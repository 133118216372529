﻿/* global tumarket */

import * as $ from "jquery";

import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/popover";
import "bootstrap/js/dist/modal";
import tumGlobal from "../global.js";
import tumCommon from "../common.js";
import "readmore-js";
import min from "../search/miniature.js";
import tu_slider from "../slider.js";
import { ajaxSend } from "../global/fetch";
import { getUrlParameter } from "../global/url";
import { showAdultWarning } from "../product/restrictAdult.js";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { toggleReadmore } from "../global/readmore";
import { bootstrapAlert } from "../global/alert";
import { addEventDelegate, appendHtml, insertHtmlBefore, toggleClass } from "../global/dom";
import { showAlert } from "../global/dialog";
import { isMob } from "../global/util";
import { initTuPopover } from "../global/popover";

var firm = {
	options: {
		firmID: 0,
		url: {
			deleteLogo: '/firmRequests/deleteLogo'
		}
	},
	sliders: [],
	init: function (options) {
		firm.options = $.extend(firm.options, options);

		this.initCallbacks();

		$("#asModal").modal({ show: false, backdrop: 'static' });
		$('.tum-firm-field').removeClass('d-none');

		if (tumarket.firm.canEdit) {
			import("../as/as.sys.js").then(m => {
				let as = m.default;

				import("../as/controls/as.uploadHTML5").then(() => {
					as.uploadHTML5.init({
						uploadCallback: function () {
							$("fieldset.photoUploadDescBlock").removeClass("d-none");
						},
					});
				})
			});
		}

		$('.tum-readmore').removeClass('hide');
		$('.tum-readmore').readmore({
			moreLink: '<a class="tum-grey readMore" href="#" rel="nofollow"><small><i class="fas fa-chevron-down"></i></small></a>',
			lessLink: '<a class="tum-grey readLess" href="#" rel="nofollow"><small><i class="fas fa-chevron-up"></i></small></a>',
			speed: 100,
			collapsedHeight: 20
		});

		firm.initSliders();

		$(".tum-dropleft-menu-title-header").text("Меню фирмы");

		if (getUrlParameter('tab') == 'Contacts') {
			toggleClass('.tum-main-contact', 'd-none');
			$('#shortcontact').collapse('show');

			tumGlobal.highlight($(".firm-contacts-cont"), 5000);
		}

		if (new URL(location.href).searchParams.get("openOrderProcessing") == "1") {
			if ($(".departOrder").length) {
				$(".departOrder")[0]?.click();

				var offset = $(".departOrder").offset();
				offset.top -= 50;
				$('html, body').animate({
					scrollTop: offset.top
				});
			}
		}

		if (getUrlParameter("showOnMap") == "1") {
			$(".companyMapLink")[0]?.click();
		}
	},
	showHeaderHint: function ($el) {
		var textCont = $el.attr("data-textcontainer");
		var text = $(textCont).html();

		$(".tum-firm-header-hint").html('<div class="d-flex justify-content-between"><span><i class="fa fa-question-circle hintIcon" aria-hidden="true"></i> <a href="#" rel="nofollow" class="tum-firm-header-hideHint">Закрыть</a></span><a href="javascript:void(0)" class="tum-firm-header-hideHint"><i class="fas fa-times tum-grey"></i></a></div>' + text);
		$(".tum-firm-header-hint").removeClass("d-none");

		var offset = $(".tum-firm-header-hint").offset();
		offset.top -= 100;
		$('html, body').animate({
			scrollTop: offset.top
		});

		tumGlobal.highlight($(".tum-firm-header-hint"), 1500);
	},
	checkNeedSlider: function (el) {
		firm.initFirmSlider(el.querySelector('.tu-slider'));
	},
	initCallbacks: function () {
		var self = this;

		addEventDelegate(document, 'click', '[data-drop-sliders-cache]', () => this.dropSlidersCache());

		addEventDelegate(document, 'click', ".b-product-annotation, .b-firm-annotation, .tum-pricedesc", element => {
			$(element).readmore("toggle");
		});

		addEventDelegate(document, 'click', ".tum-readmore", element => {
			$(element).readmore("toggle");
		});

		addEventDelegate(document, 'click', ".tum-firmOffer-desc-toggle", () => {
			toggleReadmore($('.tum-firmOffer-desc'));
		});

		addEventDelegate(document, 'click', '#openModal', (element, e) => {
			if (!$(e.target).hasClass('close')) {
				$(element).find('.close')[0]?.click();
			}
		});
		
		addEventDelegate(document, "click", "[data-show-firm-logo]", (element, e) => {
			e.preventDefault();
			$(element).next().css("display", "block");
			tumCommon.toggleFloatingHeader();
			tumCommon.updateFloatingHeader();
			tumCommon.toggleTopBar();
		});

		addEventDelegate(document, "click", ".modalDialog .close", (element, e) => {
			e.preventDefault();
			$(element).closest(".modalDialog").css("display", "none");
			tumCommon.toggleFloatingHeader();
			tumCommon.updateFloatingHeader();
			tumCommon.toggleTopBar();
		});

		$('[data-toggle="popover"]').popover({
			sanitize: false
		});

		addEventDelegate(document, 'click', '.tum-isFix-hint', element => {
			let text = '';
			let contentTarget = $(element).attr("data-contenttarget");

			if (contentTarget.length) {
				text = $(contentTarget).html();
			} else {
				text = $(element).attr("data-content");
			}
			var elem = $(element).closest(".hasHint");
			if ($(element).hasClass("show")) {
				firm.showHint(text, elem, true);
				return;
			}
			firm.showHint(text, elem);
			$(element).addClass("show");
		});

		addEventDelegate(document, 'click', ".hideHint", (element, e) => {
			e.preventDefault();
			$(".tum-hint-block").removeClass("show");
			$(".tum-isFix-hint").removeClass("show");
		});

		addEventDelegate(document, 'click', ".tum-firm-header-hideHint", (element, e) => {
			e.preventDefault();
			var $hintBlock = $(element).closest(".tum-firm-header-hint");
			$hintBlock.addClass("d-none");
		});

		$(document).on('mouseover', ".header-dropleft-menu", function() {
			min.getMenuItems(this);
		});

		$('.tum-firms-sliders').on('shown.bs.collapse', '.slider-block', function (e) {
			var $tape = $(this);
			if ($tape.is(e.target) && !$tape.hasClass('isInit')) {
				firm.checkNeedSlider(this);
				$(this).addClass('isInit');
			}
		});

		addEventDelegate(document, 'click', ".tum-firm-header-showHint", element => {
			firm.showHeaderHint($(element));
		});

		addEventDelegate(document, 'click', ".firm-deleteLogo", () => {
			if (!confirm("Вы уверены, что хотите удалить логотип?")) {
				return;
			}

			ajaxSend({ url: firm.options.url.deleteLogo, data: { firmID: firm.options.firmID } }).then(data => {
				if (data.result) {
					showAlert("Логотип удален", { type: "success" });
					setTimeout(function () {
						location.reload();
					}, 2000);
				}
				else {
					showAlert("Ошибка при удалении логотипа", { type: "danger" });
				}
			});
		});

		//ридмор
		addEventDelegate(document, 'click', '.tum-modal-hint', element => {
			$(element).readmore('toggle');
		});

		addEventDelegate(document, 'click', '.tum-show-slogan', (element, e) => {
			e.preventDefault();
			firm.changeCollapseIcon($(element).find('i'));

			let $readmore = $('.fcReadMore .tum-readmore-1');

			if ($readmore.length) {
				toggleReadmore($readmore);
			}

			if ($readmore.hasClass('show')) {
				tumGlobal.scrollUp();
				tumGlobal.highlight($readmore);	
			} 
		});

		addEventDelegate(document, 'click', '.tum-show-firm-contacts', () => {
			toggleClass('.tum-main-contact', 'd-none');
		});

		//дубль кода,вынужденно такой же в offerSearch.js
		$('.tum-show-panel-contacts').on('hidden.bs.collapse', function () {
			var a = $(this).closest('.firm-contacts-cont').find('.tum-show-firm-contacts');
			$(a).html('<i class="fas fa-angle-down pr-1"></i>&nbsp;показать');
		});

		$('.tum-show-panel-contacts').on('shown.bs.collapse', function () {
			var a = $(this).closest('.firm-contacts-cont').find('.tum-show-firm-contacts');
			$(a).html('<i class="fas fa-angle-up pr-1"></i>&nbsp;свернуть');
		});

		$('.tum-show-panel-departs').on('hidden.bs.collapse', function () {
			var icon = $(this).closest('.firm-depart-cont').find('.tum-show-all-contacts i');
			$(icon).removeClass('fa-angle-up');
			$(icon).addClass('fa-angle-down');
		});

		$('.tum-show-panel-departs').on('show.bs.collapse', function () {
			var icon = $(this).closest('.firm-depart-cont').find('.tum-show-all-contacts i');
			$(icon).removeClass('fa-angle-down');
			$(icon).addClass('fa-angle-up');
		});

		$('.tum-offer-show-panel-schedule').on('hidden.bs.collapse', function () {
			var icon = $(this).closest('.tum-firm-schedule-cont').find('a + i');
			$(icon).removeClass('fa-angle-up');
			$(icon).addClass('fa-angle-down');
		});

		$('.tum-offer-show-panel-schedule').on('show.bs.collapse', function () {
			var icon = $(this).closest('.tum-firm-schedule-cont').find('a + i');
			$(icon).removeClass('fa-angle-down');
			$(icon).addClass('fa-angle-up');
		});

		addEventDelegate(document, 'click', '.tum-firm-schedule a + i', () => {
			$('.tum-firm-schedule > a:first')[0]?.click();
		});

		addEventDelegate(document, 'click', '.tum-collapse-link-anno', element => { firm.collapseLinkAnnoClick($(element)) });
		addEventDelegate(document, 'click', '.tum-collapse-link-anno-header', element => { firm.collapseLinkAnnoHeaderClick($(element)) });

		addEventDelegate(document, 'click', '.tum-uncollapse-link', (element, e) => {
			e.preventDefault();
			$('.tum-collapse-link[href="' + $(element).attr('href') + '"]').removeClass('d-none');
		});

		addEventDelegate(document, "click", ".companyMapLink", (element, e) => {
			e.preventDefault();

			//подменяем html на "закрыть" только для основной кнопки (которая на странице всегда, а не в поповере в шапке)
			//независимо от того, на какую нажимали
			let $btn = $('.companyMapLink').not('.companyMapLink_popover');
			let html = $btn.html();
			let althtml = $btn.attr('data-althtml');
			$btn.html(althtml);
			$btn.attr('data-althtml', html);

			if ($(element).attr('aria-expanded') != "false") {
				if (!window.ymaps) {
					self.initYmaps();
				}
			}

			$(element).toggleClass("tum-dark-red");
		});

		addEventDelegate(document, 'click', ".collapseGeoHeader, .collapseGeoContent, .fcFirmType", element => {
			$(element).next().click();
		});

		$(document).on('show.bs.collapse', '#mediaBlock', function(){
			var i = $(this).prev().find('i.fas');
			firm.changeCollapseIcon(i);
		});
		$(document).on('hide.bs.collapse', '#mediaBlock', function(){
			var i = $(this).prev().find('i.fas');
			firm.changeCollapseIcon(i);
		});

		$(document).on('show.bs.collapse hide.bs.collapse', '#profileBlock,#showcaseBlock,#salesBlock,#answerBlock,#chatBlock,#firmBlock,#productBlock,#bannerBottomBlock,#bannerTopBlock', function(){
			var i = $(this).prev().find('i.fas');
			firm.changeCollapseIcon(i);
		});

		$(document).on('show.bs.collapse', '#profileBlock,#showcaseBlock,#salesBlock,#answerBlock,#chatBlock,#firmBlock,#productBlock,#bannerBottomBlock,#bannerTopBlock', function(){
			$('.tum-readmore', this).css("height", "15px");
		});
	},
	changeCollapseIcon: function (i) {
		if ($(i).hasClass('fa-angle-down')) {
			$(i).removeClass('fa-angle-down');
			$(i).addClass('fa-angle-up');
		} else {
			$(i).removeClass('fa-angle-up');
			$(i).addClass('fa-angle-down');
		}
	},
	collapseLinkAnnoClick: function () {
		$('.tum-collapse-link-anno').addClass('d-none');
	},
	collapseLinkAnnoHeaderClick: function () {
		var annoCont = $('.tum-firm-anno');
		annoCont.addClass('bg-info');
		setTimeout(function () {
			annoCont.removeClass('bg-info');
		},
			3000);
	},
	initFirmSlider: function (el) {
		let totalIds = el.closest('.slider-block').getAttribute('data-ids')?.split(',');
		let contextType = el.closest('.slider-block').getAttribute('data-context');

		let getItems = (callback) => {
			let productIds = totalIds.slice(tumarket.defaultSliderSize);
			loadPrecompiledTemplates(['product-mini-wrapper']).then(() => {
				return ajaxSend({ url: '/search/GetProductMiniatures', data: { 
					productIDs: productIds, 
					isNeedLinkUnder: true,
					showContacts: false
				} });
			}).then(data => {
				if (data.result) {
					let template = getPrecompiledTemplate('product-mini-wrapper');
					for (let item of data.Items) {
						item.isSlider = true;
						item.isMob = isMob();
						item.contextType = contextType;
					}

					let newItems = data.Items.map(e => template(e)).join("");

					let additionalSlide = el.querySelector('.slider-list .slider-additional-slide');
					if (additionalSlide) {
						insertHtmlBefore(el.querySelector('.slider-list .slider-additional-slide'), newItems);
					} else {
						appendHtml(el.querySelector('.slider-list'), newItems);
					}
					
					callback();
					initTuPopover();
				}
			});
		};

		if (!totalIds) getItems = null;

		var slider = tu_slider.create({ 
			container: el,
			getItems: getItems,
			totalCount: totalIds ? totalIds.length + 1 : null
		});

		if (slider) this.sliders.push(slider);
		firm.initSlidersReadmore(el);
		$(".visibility-sliders [data-toggle=popover]").popover({
			html: true,
			sanitize: false
		});
	},
	initSlidersReadmore: function (el) {
		$('.b-firm-annotation', el).readmore({
			moreLink: '<a class="tum-grey readMore no-font-scaling" href="#" rel="nofollow"><small><i class="fas fa-chevron-down"></i> Ещё</small></a>',
			lessLink: '<a class="tum-grey readLess no-font-scaling" href="#" rel="nofollow"></a>',
			speed: 100,
			collapsedHeight: 97
		});
	},
	initSliders: function () {
		document.querySelectorAll('.tum-firms-sliders .b-catalog-slider .tu-slider').forEach(el => {
			firm.initFirmSlider(el);
		});
		
		showAdultWarning();

		if ($(".tum-readmore-showcase").length > 0 && $(".block-readmore-window").length > 0 && $(".tum-readmore-showcase .tum-firmOffer-showcase").length == 0) {
			$('.tum-readmore-showcase').append($(".block-readmore-window .tum-showcase"));
			$(".tum-firmOffer-showcase").addClass("tum-readmore");
			if ($("#showcaseBlock.collapse.show").length == 1) {
				//если Витрина открыта, сразу инициализируем ридмор
				$('.tum-firmOffer-showcase.tum-readmore').readmore({
					moreLink: '<a class="tum-grey readMore" href="#" rel="nofollow"><small><i class="fas fa-chevron-down"></i></small></a>',
					lessLink: '<a class="tum-grey readLess" href="#" rel="nofollow"><small><i class="fas fa-chevron-up"></i></small></a>',
					speed: 100,
					collapsedHeight: 20
				});
			}
		}

		if (new URL(location.href).searchParams.get("openShowcase") == "1") {
			if ($(".b-catalog-slider-showcase").length) {
				$('#showcaseBlock').collapse('show');
				var offset = $(".b-catalog-slider-showcase").offset();
				offset.top -= 50;
				$('html, body').animate({
					scrollTop: offset.top
				});

				$(".b-catalog-slider-showcase").addClass("tum-box-attention");

				setTimeout(function () {
					$(".b-catalog-slider-showcase").removeClass("tum-box-attention");
				}, 500);
			}
		}
	},
	showHint: function (txt, elem, close) {
		var hintBlock = $(elem).find(".tum-hint-block");
		if (close) {
			$(".tum-hint-block").removeClass("show");
			$(".tum-isFix-hint").removeClass("show");
			return;
		}
		$(".tum-hint-block").removeClass("show");
		hintBlock.html('<div class="d-flex justify-content-between"><span><i class="fa fa-question-circle hintIcon" aria-hidden="true"></i> <a href="#" rel="nofollow" class="hideHint">Закрыть</a></span><a href="javascript:void(0)" class="hideHint"><i class="fas fa-times tum-grey"></i></a></div>' + txt);
		hintBlock.addClass('hintBorder');
		hintBlock.addClass("show");
	},
	initYmaps: function(){
		var self = this;
		$('#contractor-pricelist-map').html('');

		function initMap(){
			import("../map.js").then(({ tu_maps }) => {
				self.map = tu_maps.create({
					containerId: 'contractor-pricelist-map',
					center: tumarket.firm.geo,
					height: null,
					zoom: Number(getUrlParameter("mapZoom")) || 12,
					onLoad: (ymap, modules) => {
						let placemark = new modules.Placemark([tumarket.firm.geo.lat, tumarket.firm.geo.long]); 
						ymap.geoObjects.add(placemark);
					},
					lockerContainer: ".map-locker"
				});
			});
		}

		tumGlobal.loadYmaps().then(() => initMap());
	},
	dropSlidersCache: function() {
		ajaxSend({ 
			url: '/requests/dropSlidersCache', 
			data: { firmId: this.options.firmID }
		}).then(data => {
			if (data.result) {
				showAlert(`Данные обновлены. <a href="javascript:location.reload()">Обновить страницу</a>`, { type: "success2" });
			} else {
				bootstrapAlert("Ошибка при обновлении данных", { type: 'danger' });
			}
		});
	}
};

export default firm;